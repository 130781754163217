<script setup lang="ts">
const { getProductsRecentlyViewed } = useRecentlyViewed('recently-viewed')
const { data: products } = await getProductsRecentlyViewed()
const { pdpPath } = useRouteHelper()
const { ts } = useI18n()
</script>

<template>
  <div v-if="products && products?.length > 0">
    <MyAccountCardContainer
      :header="{ title: ts('myAccount.logged.recentlyViewed.title') }"
      :description="ts('myAccount.logged.recentlyViewed.description')"
    >
      <template #cardContent>
        <div>
          <div class="text-light-6 flex flex-col gap-6">
            <div class="flex">
              <NuxtLink
                v-for="item in products"
                :key="item.objectID ?? item.MFC"
                :to="pdpPath(item)"
                :title="ts('myAccount.logged.recentlyViewed.title')"
                class="border-primitives-grey-100 border odd:-mx-[1px]"
              >
                <NuxtPicture
                  v-if="item.assets?.[0]?.URL"
                  provider="cloudinary"
                  :src="item.assets[0].URL"
                  :alt="item?.Name"
                  class="block h-min"
                  sizes="75px"
                  fit="fill"
                  loading="lazy"
                />
              </NuxtLink>
            </div>
          </div>
        </div>
      </template>
    </MyAccountCardContainer>
  </div>
</template>
